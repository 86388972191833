<!--
 * @Author: wxy
 * @Describe: 
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 22:41:03
-->
<template>
  <a-row :gutter="20" class="margin-bottom" type="flex" align="middle">
    <a-col>
      <a-button :disabled="tableLoading" type="primary" @click="refresh(filterState)">刷新</a-button>
    </a-col>
    <a-col>
      <a-button :disabled="tableLoading" type="primary" @click="exortTable('养牛小程序-用户数据导出',filterState)">导出</a-button>
    </a-col>
    <a-col>
      <a-input
        v-model:value="filterState.userName"
        placeholder="请输入用户名"
        allow-clear
      />
    </a-col>

    <a-col>
      <a-input
        v-model:value="filterState.userId"
        placeholder="请输入用户ID"
        allow-clear
      />
    </a-col>

    <a-col>
      <a-button type="primary" :disabled="tableLoading" @click="filterFn(search)">搜索</a-button>
    </a-col>
  </a-row>

  <a-table
    :dataSource="dataSourceRef"
    :columns="columns"
    :loading="tableLoading"
    :pagination="pagination"
    :scroll="{
      x: 1000,
      y: 580,
      scrollToFirstRowOnChange: true,
    }"
    @change="tableChange"
  />
</template>

<script>
import useTable from "@/composition/useTable";
import useTableFilter from "@/composition/useTableFilter";
import { createVNode } from "vue";
import { Image } from "ant-design-vue";
export default {
  setup() {
    const {
      exortTable,
      dataSourceRef,
      pagination,
      scroll,
      tableChange,
      editData,
      tableLoading,
      getData,
      refresh,
      search,
    } = useTable({ get: "/background/user",export:'/background/downloadUser' });

    // 篩選數據
    const { filterState, filterFn } = useTableFilter("/background/user", {
      userName: "",
      userId: "",
    });

    return {
      exortTable,
      refresh,
      dataSourceRef,
      filterState,
      filterFn,
      search,
      pagination,
      scroll,
      tableChange,
      editData,
      tableLoading, 
      getData,
      columns: [
        {
          title: "用户头像",
          dataIndex: "userImage",
          key: "userImage",
          align: "center",
          customRender: (text) =>
            new Object({
              children: createVNode(Image, {
                style: { width: "100px", height: "100px" },
                src: text.text,
              }),
            }),
          width: 100,
          fixed: "left",
        },
        {
          title: "用户名称",
          dataIndex: "userName",
          key: "userName",
          align: "center",
          width: 200,
          fixed: "left",
        },
        {
          title: "用户ID",
          dataIndex: "userId",
          key: "userId",
          align: "center",
          width: 200,
        },
        {
          title: "剩余抽取次数",
          dataIndex: "extractNum",
          key: "extractNum",
          align: "center",
          width: 150,
        },

        {
          title: "是否确定小牛",
          dataIndex: "isSureCow",
          key: "isSureCow",
          align: "center",
          customRender: (text) =>
            new Object({ children: text.isSureCow == 1 ? "是" : "否" }),
          width: 150,
        },
        {
          title: "当前抽到小牛编号",
          dataIndex: "cowNoNow",
          key: "cowNoNow",
          align: "center",
          width: 150,
        },
        {
          title: "是否首次登陆",
          dataIndex: "isFirstLogin",
          key: "isFirstLogin",
          align: "center",
          customRender: (text) =>
            new Object({ children: text.isFirstLogin == 0 ? "是" : "否" }),
          width: 150,
        },
        {
          title: "产奶总量",
          dataIndex: "milkYieldSum",
          key: "milkYieldSum",
          align: "center",
          width: 150,
        },
        {
          title: "剩余饲料",
          dataIndex: "feedSum",
          key: "feedSum",
          align: "center",
          width: 150,
        },
        {
          title: "牧草数量",
          dataIndex: "grassNum",
          key: "grassNum",
          align: "center",
          width: 150,
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          key: "createTime",
          align: "center",
          width: 200,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
